import './card.scss';
import { Link } from 'react-router-dom';
import Button from '../../Mainscreen/components/button/Button';

const Card = props => {
    const item  = props.item;
    const link = '/' +  item.title_params;
    const bg = item.img
    const imageUrl = `https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/${bg}`;


    return (
        <Link className="card-link" target="_top" to={link} >
            <div className="movie-card" style={{ backgroundImage: `url(https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/${bg})` }}>
                <Button>
                    <p className="watch-btn">GLEDAJ</p>
                </Button>
            </div>
            <h3>{item.title}</h3>
        </Link>
    );
}

export default Card;